import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Home_Assistant_Tutorials/PrimaryBox.jsx';
import PrimaryBox from 'components/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/PrimaryBox';
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT Server mit Home Assistant",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/",
  "dateChanged": "2019-03-26",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) ist ein Machine-to-Machine (M2M)/ Internet of Things (IoT) Verbindungsprotokoll. Es wurde als ressourcenschonender Publish/Subscribe Messaging-Transport konzipiert. Es ist nützlich für Verbindungen wo ein geringer Speicherbedarf erforderlich ist und/oder die Netzwerkbandbreite begrenzt ist. Alle Full-HD-Kameras verfügen jetzt über einen internen MQTT-Broker: IN-8001 Full HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Home Assistant"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='INSTAR MQTT Server mit Home Assistant' dateChanged='2019-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) ist ein Machine-to-Machine (M2M)/ Internet of Things (IoT) Verbindungsprotokoll. Es wurde als ressourcenschonender Publish/Subscribe Messaging-Transport konzipiert. Es ist nützlich für Verbindungen wo ein geringer Speicherbedarf erforderlich ist und/oder die Netzwerkbandbreite begrenzt ist. Alle Full-HD-Kameras verfügen jetzt über einen internen MQTT-Broker: IN-8001 Full HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_HomeAssistant.png' twitter='/images/Search/AU_SearchThumb_HomeAssistant.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/Home_Assistant/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "instar-mqtt-server-mit-home-assistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-server-mit-home-assistant",
        "aria-label": "instar mqtt server mit home assistant permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Server mit Home Assistant`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation-von-home-assistant-auf-einem-raspberry-pi"
        }}>{`Installation von Home Assistant auf einem Raspberry Pi`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#mqtt-binding"
            }}>{`MQTT Binding`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#testen-unseres-mqtt-service"
            }}>{`Testen unseres MQTT Service`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hinzuf%C3%BCgen-eines-ui-schalters"
        }}>{`Hinzufügen eines UI Schalters`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#schalter-komponente"
            }}>{`Schalter Komponente`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#konfiguration-der-benutzeroberfl%C3%A4che"
            }}>{`Konfiguration der Benutzeroberfläche`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hinzuf%C3%BCgen-ihrer-kameras-live-video"
        }}>{`Hinzufügen Ihrer Kameras Live-Video`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hinzuf%C3%BCgen-einer-ui-tastfl%C3%A4che"
        }}>{`Hinzufügen einer UI-Tastfläche`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#automatisierungen"
        }}>{`Automatisierungen`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#testen-sie-den-service"
            }}>{`Testen Sie den Service`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#tagnachtschaltung-der-erkennungsbereiche"
            }}>{`Tag/Nachtschaltung der Erkennungsbereiche`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#verwendung-von-home-assitant-als-alarmserver-f%C3%BCr-ihre-kamera"
        }}>{`Verwendung von Home Assitant als Alarmserver für Ihre Kamera`}</a></li>
    </ul>
    <div className="primarybox">
    <h3>Updates:</h3>
    <ul>
        <li><a href="">Verwenden Sie den INSTAR MQTT Alarmserver mit Home Assistant</a></li>
        <li><a href="/de/Frequently_Asked_Question/INSTAR_MQTT_Self_Signed_SSL_Certificate/">Selbstsigniertes SSL-Zertifikat für den INSTAR MQTT-Broker</a></li>
        <li><a href="/de/Frequently_Asked_Question/INSTAR_MQTT_Node-RED_Self-Signed_Certificate/">Selbstsigniertes SSL-Zertifikat für den INSTAR MQTT-Broker und Node-RED</a></li>
        <li><a href="/de/Frequently_Asked_Question/INSTAR_MQTT_CA_SSL_Certificate/">CA SSL-Zertifikat für den INSTAR MQTT-Broker</a></li>
    </ul>
    </div>
    <h2 {...{
      "id": "installation-von-home-assistant-auf-einem-raspberry-pi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation-von-home-assistant-auf-einem-raspberry-pi",
        "aria-label": "installation von home assistant auf einem raspberry pi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation von Home Assistant auf einem Raspberry Pi`}</h2>
    <p>{`Wir haben bereits vor einiger Zeit einen `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Home_Assistant/"
      }}>{`Installationsleitfaden für den Home Assistant`}</a>{` für debianbasierte Linux-Systeme veröffentlicht. Aber nach der `}<a parentName="p" {...{
        "href": "https://www.home-assistant.io/docs/installation/raspberry-pi/"
      }}>{`Installationsanweisung`}</a>{` und dem `}<a parentName="p" {...{
        "href": "https://www.home-assistant.io/docs/autostart/systemd/"
      }}>{`SystemD Service Setup`}</a>{` stellten wir fest, dass sich ein paar Dinge geändert hatten. Wir haben daher die Dokumentation aktualisiert, um Home Assistant auf unserem Raspberry Pi 3 mit `}<a parentName="p" {...{
        "href": "https://www.raspberrypi.org/downloads/raspbian/"
      }}>{`Raspbian Buster`}</a>{` zu installieren.`}</p>
    <h3 {...{
      "id": "mqtt-binding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mqtt-binding",
        "aria-label": "mqtt binding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Binding`}</h3>
    <p>{`Sie sind jetzt in der Lage sind, bereits während des ersten Setups einen MQTT Broker hinzuzufügen. Ein editieren der `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Home_Assistant/#mqtt-broker-hinzuf%C3%BCgen"
      }}>{`Konfiguration YAML Dateien`}</a>{` ist somit hier nicht mehr erforderlich:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f7f784622de4d04f2b1700c4963dae39/1ff84/Home_Assistant_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA+UlEQVQoz43RQWqEMBTG8TloxMFFkzvoATp4ADUKIsWjdFcLBY9RjInmvURH04VoO1Bm5s+3yOYHgXd62aOUBkEQRVFZllVVvd1W13UYhkEQUEoPcjpejDFCSFEUQggAuP5pmibnXJ7nhBDG2P/Y8zzOOQAIIaSUSqlhGJRSUkprbZqmnufdw1mWDcPQdR0i2j1jjHPuKQxaAwAaPAKAZVkeY855J3oppUE8hgBuXR9hQjLOe3Md0aKd0NptGlEjPvVtbaZvpbsRe7DbuhHlCEmS3MPbqdZlQcR5nt3euq6PT+X7fhzHbdt+3vbRNO/N1+vlcj77lP7iH0/PdPQtd1ssAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f7f784622de4d04f2b1700c4963dae39/e4706/Home_Assistant_01.avif 230w", "/en/static/f7f784622de4d04f2b1700c4963dae39/d1af7/Home_Assistant_01.avif 460w", "/en/static/f7f784622de4d04f2b1700c4963dae39/7f308/Home_Assistant_01.avif 920w", "/en/static/f7f784622de4d04f2b1700c4963dae39/781d2/Home_Assistant_01.avif 1040w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f7f784622de4d04f2b1700c4963dae39/a0b58/Home_Assistant_01.webp 230w", "/en/static/f7f784622de4d04f2b1700c4963dae39/bc10c/Home_Assistant_01.webp 460w", "/en/static/f7f784622de4d04f2b1700c4963dae39/966d8/Home_Assistant_01.webp 920w", "/en/static/f7f784622de4d04f2b1700c4963dae39/02506/Home_Assistant_01.webp 1040w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f7f784622de4d04f2b1700c4963dae39/81c8e/Home_Assistant_01.png 230w", "/en/static/f7f784622de4d04f2b1700c4963dae39/08a84/Home_Assistant_01.png 460w", "/en/static/f7f784622de4d04f2b1700c4963dae39/c0255/Home_Assistant_01.png 920w", "/en/static/f7f784622de4d04f2b1700c4963dae39/1ff84/Home_Assistant_01.png 1040w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f7f784622de4d04f2b1700c4963dae39/c0255/Home_Assistant_01.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Wenn Sie zum ersten Mal auf die Benutzeroberfläche zugreifen, klicken Sie auf Hinzufügen einer Erweiterung und wählen Sie MQTT aus der Liste. Geben Sie nun die IP-Adresse der INSTAR Full HD IP Kamera ein (z.B. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.165`}</code>{`) gefolgt vom Port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1883`}</code>{` und Ihrem INSTAR MQTT Broker-Login ein - Sie können diesen Port im `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`Features Menü`}</a>{` ihrer Kamera ändern.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ddbc7f000fdc6cae56a864316c5449e1/620ae/Home_Assistant_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAk0lEQVQY043QvQrDIBDA8bxpFFs9t0iWNErq8zn4Bs0iGJohtBFicUlpoYVS+/HjpoP/DVfQnM3d9oF+UGS3AMA5Z4zRr15ixhghRAhhjLHW9v3BOee9V0phjN9vZeK6rsfxGEJYliWltK6r1rosy7/iqqqGYYgxhjCfzvMULl23Rwj9iJ+appFStm27u40EgOzbrvFsqOgl9pUnAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ddbc7f000fdc6cae56a864316c5449e1/e4706/Home_Assistant_02.avif 230w", "/en/static/ddbc7f000fdc6cae56a864316c5449e1/d1af7/Home_Assistant_02.avif 460w", "/en/static/ddbc7f000fdc6cae56a864316c5449e1/7f308/Home_Assistant_02.avif 920w", "/en/static/ddbc7f000fdc6cae56a864316c5449e1/f6f1b/Home_Assistant_02.avif 1043w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ddbc7f000fdc6cae56a864316c5449e1/a0b58/Home_Assistant_02.webp 230w", "/en/static/ddbc7f000fdc6cae56a864316c5449e1/bc10c/Home_Assistant_02.webp 460w", "/en/static/ddbc7f000fdc6cae56a864316c5449e1/966d8/Home_Assistant_02.webp 920w", "/en/static/ddbc7f000fdc6cae56a864316c5449e1/88fcb/Home_Assistant_02.webp 1043w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ddbc7f000fdc6cae56a864316c5449e1/81c8e/Home_Assistant_02.png 230w", "/en/static/ddbc7f000fdc6cae56a864316c5449e1/08a84/Home_Assistant_02.png 460w", "/en/static/ddbc7f000fdc6cae56a864316c5449e1/c0255/Home_Assistant_02.png 920w", "/en/static/ddbc7f000fdc6cae56a864316c5449e1/620ae/Home_Assistant_02.png 1043w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ddbc7f000fdc6cae56a864316c5449e1/c0255/Home_Assistant_02.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Wenn Sie den Home Assistant bereits installiert haben, gehen Sie auf `}<strong parentName="p">{`Settings`}</strong>{` und `}<strong parentName="p">{`Integrations`}</strong>{` und wählen Sie dort den `}<em parentName="p">{`MQTT Broker`}</em>{` hinzuzufügen:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "866px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c115449d2e707cd5426eb8f6cc5befad/c1328/Home_Assistant_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB+0lEQVQoz3WQ227UMBRFgwTv/XVK4QHeQAie+gt8AEiIiyYzZdppO5nEduLEl9jHl9hGSZSh9LK0deRE2t77OFNKSSkJIQiTjqA11S8+9c8+9ifn6uRcPf+ssvd99uEJWWuNMUIIDeCtYca/ydVprt5d6Lcb/XqtXq7U6Uq9yvXZGv5pY842JlMTjLEQQpoIivmOeNYYigJvXIsdRRFkSjGlYZJPyaXkMinl7E8LTduVmBwQrhCpMDlUuES4InWJMKobXDe8V+C8ti5zE0KIY3IvpZjhXI6Dc8amLy44NwDOGGetszbjnN9NjjEa51rOKesoYz1ATCksdePSbojRD0OW/iemBA3lt4UoDrNkWU6Hgu8LeSgNqScR07H75hRCn6/LVU62W0DYYGIQdnUdOxbbLrRtoG1oWt92Nt88SA7BXu309Y0pDnZfmH0Bt3tbHALCs+KkASF3efVIbQ26pdRa64zx3s8vcU/B+8d2jhG0ppQCgHNuGIb0BN777OFfpVRd15xzY0wIY8aRYcFOZGONeztb65y7axjncnALk3lcM8YYRk03aa2rCkkpzQQAzDkG4LjF3CjrdkWzR9fr7U2+rf7soCIAmtK2qRshxJxwnHGpGWMczasvXze/dr+/X25+XKL8qvn202rNOBdC9LKHOVyD1nD38ebkv0eLgdXDZIP8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c115449d2e707cd5426eb8f6cc5befad/e4706/Home_Assistant_03.avif 230w", "/en/static/c115449d2e707cd5426eb8f6cc5befad/d1af7/Home_Assistant_03.avif 460w", "/en/static/c115449d2e707cd5426eb8f6cc5befad/95443/Home_Assistant_03.avif 866w"],
              "sizes": "(max-width: 866px) 100vw, 866px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c115449d2e707cd5426eb8f6cc5befad/a0b58/Home_Assistant_03.webp 230w", "/en/static/c115449d2e707cd5426eb8f6cc5befad/bc10c/Home_Assistant_03.webp 460w", "/en/static/c115449d2e707cd5426eb8f6cc5befad/e299d/Home_Assistant_03.webp 866w"],
              "sizes": "(max-width: 866px) 100vw, 866px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c115449d2e707cd5426eb8f6cc5befad/81c8e/Home_Assistant_03.png 230w", "/en/static/c115449d2e707cd5426eb8f6cc5befad/08a84/Home_Assistant_03.png 460w", "/en/static/c115449d2e707cd5426eb8f6cc5befad/c1328/Home_Assistant_03.png 866w"],
              "sizes": "(max-width: 866px) 100vw, 866px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c115449d2e707cd5426eb8f6cc5befad/c1328/Home_Assistant_03.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/bcb8c/Home_Assistant_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABLklEQVQY003NzUoCURjG8XMBLSqIVtGVtCiIFkFmuUnnNO85856Zwzi3Vos2UVEKRlpOqBA6gy6iKEgMLNT50GGimQj//LYPD+GcM8YopQql7Jjm0FzeL65mi+s5a+3QWslai5ni4p65lDEXdiXZ5GRrDiJqmsYYQ0QAOL+4rNjN8v1jqVYvVetXt7Xruwe77T5/DM5uKhsHhR0Ft/NaijDG0nPDMFRVdTqdKAz88TgKw2ngh74XhcFsOo3juOs6oOQtqZs6pgjnXEuSUqqq+tRuj33/azSaBMG/kedFcdxotY7yBaEbGooEknQJAL/PAK1qdfjy+v32Hn4OgsEfr9+PhsOGbSuUSil1XRdCICIBAJ6Ujhsnp57jet2eP2fS7c0ct1kuKwDSMPQkIcQP9djPpWdkK4YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/e4706/Home_Assistant_04.avif 230w", "/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/d1af7/Home_Assistant_04.avif 460w", "/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/401c6/Home_Assistant_04.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/a0b58/Home_Assistant_04.webp 230w", "/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/bc10c/Home_Assistant_04.webp 460w", "/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/6f82a/Home_Assistant_04.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/81c8e/Home_Assistant_04.png 230w", "/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/08a84/Home_Assistant_04.png 460w", "/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/bcb8c/Home_Assistant_04.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c265d921d38a04a1a3b84e3b3e3a98a1/bcb8c/Home_Assistant_04.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "testen-unseres-mqtt-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#testen-unseres-mqtt-service",
        "aria-label": "testen unseres mqtt service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testen unseres MQTT Service`}</h3>
    <p>{`Um zu testen, ob alles funktioniert, müssen wir zu `}<strong parentName="p">{`Developer Tools`}</strong>{` und `}<strong parentName="p">{`MQTT`}</strong>{` gehen. Hier können wir ein Abonnement für das `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT Topic`}</a>{` einrichten. (`}<strong parentName="p">{`Listen to a topic`}</strong>{`) `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/status/alarm/area1/enable`}</code>{` (Bitte ersetzen Sie den Teil `}<strong parentName="p">{`000389888811`}</strong>{` durch die MAC-Adresse Ihrer Kamera.).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "862px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8639f8df9024576d11e7c50f6136ef94/f0551/Home_Assistant_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACRElEQVQ4y4VTXWsUMRQdu2rR+uarqL9IESmCguKjf8T/4Ftf1tquPlgR39tahAqyllIQbCur291JZpKZ3Nwkky+ZmbrQTm0PN3kJJ+fc3JMEEQGAEJISKrJ0h6qFAVwfwN0P8vZ7eWtN3lmTCwPorcCV1bour0BvgMlb3RvIRGuNiJzzohQOxTAzc32Y68ONd/LqKlwbyPlVuLQMSR+S15D0RfIG55fozVcHvSWaAAAiMsac9zHGkfT31vH+hnr2RT/8rB5vqQfrcnETH23KJ1u4uAFPt6sXn0Yvl7eff/yd5HkupdRaxxhDvYI3ymm0lbYag6ti8KfLu6gxBpdYa40xRVGEUHNjjHmeT6dTxth4PFZKxbMQmqqVEbEsy9mBEAIAyrIEACFEWZZFUSBiY+sEamXnnLV2Rua8SNOUUtrei4hSSqWUPQnnXNK1BEKAKG1VWWtnIt5710GHHMIE3U8ILsSuz9O2O1wvUDPUproYZ9guCp5OJ4zzyWRSVVX8P7rKoX2Mtslmfsc1w3nkmav6wbzHELmN3MXQVQ5KR92UqfegjfiHoigJIX+OJkcpmRBKCM2yjBAihGhHm4y+7u7v/doZHn4fHuwO97NvuxUAKlUZ0zqvu6gbOTHh1nyS7R8Wo/Fo7wc9HGmS2yyvKtN6jhchcd75EKy3Lvg2tEprhaiUMsa0v11K2UZNKSWlNI2pRpnXueWMZTSTAHXCmmALIaSUjLE2p6wB57z9hc65mrx9JGgpKUkJoW0zx302x+fb/gsQJiPt+h3L6AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8639f8df9024576d11e7c50f6136ef94/e4706/Home_Assistant_05.avif 230w", "/en/static/8639f8df9024576d11e7c50f6136ef94/d1af7/Home_Assistant_05.avif 460w", "/en/static/8639f8df9024576d11e7c50f6136ef94/30a87/Home_Assistant_05.avif 862w"],
              "sizes": "(max-width: 862px) 100vw, 862px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8639f8df9024576d11e7c50f6136ef94/a0b58/Home_Assistant_05.webp 230w", "/en/static/8639f8df9024576d11e7c50f6136ef94/bc10c/Home_Assistant_05.webp 460w", "/en/static/8639f8df9024576d11e7c50f6136ef94/e32b8/Home_Assistant_05.webp 862w"],
              "sizes": "(max-width: 862px) 100vw, 862px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8639f8df9024576d11e7c50f6136ef94/81c8e/Home_Assistant_05.png 230w", "/en/static/8639f8df9024576d11e7c50f6136ef94/08a84/Home_Assistant_05.png 460w", "/en/static/8639f8df9024576d11e7c50f6136ef94/f0551/Home_Assistant_05.png 862w"],
              "sizes": "(max-width: 862px) 100vw, 862px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8639f8df9024576d11e7c50f6136ef94/f0551/Home_Assistant_05.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Im Screenshot sehen Sie, dass wir ein Update erhalten haben und dass der Wert für dieses Thema 0 war: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val": "0"}`}</code>{`. Das bedeutet, dass der Alarmerkennungsbereich 1 deaktiviert wurde. Durch die Veröffentlichung (`}<strong parentName="p">{`publish`}</strong>{`) des Wertes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val": "1"}`}</code>{` unter dem Topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/alarm/area1/enable`}</code>{` wird der Bereich aktiviert. Sie können dies in der `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Web-Benutzeroberfläche`}</a>{` Ihrer Kamera überprüfen. Sie können den Bereich auch innerhalb der WebUI wieder deaktivieren und sehen, dass das MQTT-Topic wieder auf `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val": "0"}`}</code>{` umschaltet.`}</p>
    <h2 {...{
      "id": "hinzufügen-eines-ui-schalters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hinzuf%C3%BCgen-eines-ui-schalters",
        "aria-label": "hinzufügen eines ui schalters permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen eines UI Schalters`}</h2>
    <p>{`Wir wissen nun, dass wir in der Lage sind, MQTT `}<strong parentName="p">{`Status Topics`}</strong>{` von unserer Kamera zu abonnieren und diese Topicsn über das entsprechende `}<strong parentName="p">{`Command Topics`}</strong>{` zu aktualisieren. Alles, was wir jetzt noch tun müssen, ist, eine `}<strong parentName="p">{`Schalter Komponente`}</strong>{` hinzuzufügen, die dies für uns erledigt und unserem Home Assistant Dashboard zugewiesen werden kann.`}</p>
    <h3 {...{
      "id": "schalter-komponente",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#schalter-komponente",
        "aria-label": "schalter komponente permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schalter Komponente`}</h3>
    <p>{`Um unsere Haupt `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code>{` übersichtlich zu halten, erstellen wir eine separate Datei `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`switches.yaml`}</code>{`, die alle unsere MQTT-bezogenen Schalter enthält und importieren diese in die Hauptkonfiguration, indem wir die folgende Zeile hinzufügen: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`switch: !include switches.yaml`}</code>{`.`}</p>
    <p>{`Jetzt können wir unsere ersten Schalterkomponenten hinzufügen - z.B. um die Bewegungserkennungsbereiche zu aktivieren und zu deaktivieren:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Alarm Area 1'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar/000389888811/status/alarm/area1/enable'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar/000389888811/alarm/area1/enable'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Alarm Area 2'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar/000389888811/status/alarm/area2/enable'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar/000389888811/alarm/area2/enable'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Alarm Area 3'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar/000389888811/status/alarm/area3/enable'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar/000389888811/alarm/area3/enable'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Alarm Area 4'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar/000389888811/status/alarm/area4/enable'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command_topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar/000389888811/alarm/area4/enable'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload_off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`optimistic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span></code></pre></div>
    <p>{`Für jede Komponente - der wir die Schalter-Vorlage innerhalb unserer Hauptkonfiguration zuordnen - müssen wir die Plattform definieren, auf der wir sie verwenden wollen. Dies ist hier die `}<strong parentName="p">{`mqtt`}</strong>{` Plattform, die wir dem Home Assistant hinzugefügt haben.`}</p>
    <p>{`Wir haben sowohl ein `}<strong parentName="p">{`state`}{`_`}{`topic`}</strong>{` als auch ein `}<strong parentName="p">{`command`}{`_`}{`topic`}</strong>{`. Das erste ist für das Status-Topic und definiert den Zustand, in dem sich unser Schalter befindet. Das Befehlsthema ist dasjenige, mit dem wir den Zustand aktualisieren und unsere Kamera veranlassen, ihren entsprechenden internen Zustand zu ändern, z.B. unseren Alarmbereich ein- oder auszuschalten. Hier können Sie jedes der `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT Topics`}</a>{` verwenden, die zu Funktionen gehören, die von Ihrem Kameramodell unterstützt werden.`}</p>
    <p>{`Das `}<strong parentName="p">{`Message Payload`}</strong>{` ist in diesem Fall entweder `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val": "1"}`}</code>{`, um den Bereich einzuschalten, oder `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val": "0"}`}</code>{`, um den Bereich zu deaktivieren. Das sind die `}<strong parentName="p">{`payload`}{`_`}{`on`}</strong>{` und `}<strong parentName="p">{`value`}{`_`}{`payload`}</strong>{` für unseren Switch. Beachten Sie, dass Sie jedes einzelne von ihnen mit `}<em parentName="p">{`Einzelanführungszeichen`}</em>{` umgeben müssen.`}</p>
    <p>{`Jetzt sind wir in der Lage, etwas MQTT Magie ins Spiel zu bringen. Die erste Einstellung ist `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`qos`}</code>{` und steht für `}<strong parentName="p">{`Quality of Service`}</strong>{` - wobei `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` für `}<em parentName="p">{`fire-and-forget`}</em>{` steht (Ihr Client könnte ein Status-Update verlieren, wenn er die Nachricht nicht sofort erhält), `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` bedeutet, dass verifiziert wird, dass ein Status-Update empfangen wurde und `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2`}</code>{` ist für uns hier nicht relevant (bei einem qos-Wert von 2 wird sichergestellt, dass jedes Update nur einmal empfangen empfangen wird - was wichtig ist, z.B. wenn Sie Zeitreihen von Messsensoren erfassen). `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`optimistisch`}</code>{` muss verwendet werden, wenn Ihr Gerät kein separates `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`state_topic`}</code>{` hat - hier müssen Sie davon ausgehen, dass der gerade gesendete Befehl funktioniert hat und der Zustand aktualisiert wurde - für uns nicht relevant. Mit `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`retain`}</code>{` können Sie den Wert eines Topics beibehalten, auch wenn der Client oder Server nicht erreichbar sind. Auch das benötigen wir hier nicht.`}</p>
    <h3 {...{
      "id": "konfiguration-der-benutzeroberfläche",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#konfiguration-der-benutzeroberfl%C3%A4che",
        "aria-label": "konfiguration der benutzeroberfläche permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Konfiguration der Benutzeroberfläche`}</h3>
    <p>{`Nun müssen wir eine neue `}<strong parentName="p">{`View`}</strong>{` für unsere Kamera hinzufügen, indem wir die Benutzeroberfläche in den `}<em parentName="p">{`Konfigurationsmodus`}</em>{` schalten:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "868px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3cd2a0b72421d4671d78ba0b05c18c54/748b0/Home_Assistant_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABMklEQVQY01XGW0vCYACA4f20KKQoguoiyLowCbqJiiBqF56WRkKBhyjCmRstiKIprTRbgt/yuDZNcs6d3HJOkC76C2FeBS8PLzS7vOnDgR/LBa+BC6VdKO3+czjx/6Ev7kTOGybXnfvONXjLuQfNO3YRooTgIEAUvBhAcGakDwPuRN4zDHhGc5n3EMWDk9tV26J9amljbA5ybPujFBfLcPFn/px6j2X4M4pFM/zFExtJlUPJUihZDicr4VQldF+KkNVDLDs5szI+sWCbtkOw/7j9bdW7WqPXqZs63zV4UxcG1tFNeidyFSXIwCmerXHNgclJSk1W2ab4SDMUeEszRQhGgl8/vbalSv1Oy9JFy2hZutLXHxrSHdssfHy+Vtm62hYNTVAVQVVaoiTLmqxpkqL8Ag6EyNHXN5zUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3cd2a0b72421d4671d78ba0b05c18c54/e4706/Home_Assistant_06.avif 230w", "/en/static/3cd2a0b72421d4671d78ba0b05c18c54/d1af7/Home_Assistant_06.avif 460w", "/en/static/3cd2a0b72421d4671d78ba0b05c18c54/67bb0/Home_Assistant_06.avif 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3cd2a0b72421d4671d78ba0b05c18c54/a0b58/Home_Assistant_06.webp 230w", "/en/static/3cd2a0b72421d4671d78ba0b05c18c54/bc10c/Home_Assistant_06.webp 460w", "/en/static/3cd2a0b72421d4671d78ba0b05c18c54/1ae05/Home_Assistant_06.webp 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3cd2a0b72421d4671d78ba0b05c18c54/81c8e/Home_Assistant_06.png 230w", "/en/static/3cd2a0b72421d4671d78ba0b05c18c54/08a84/Home_Assistant_06.png 460w", "/en/static/3cd2a0b72421d4671d78ba0b05c18c54/748b0/Home_Assistant_06.png 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3cd2a0b72421d4671d78ba0b05c18c54/748b0/Home_Assistant_06.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Klicken Sie nun auf das `}<strong parentName="p">{`+`}</strong>{`, wie im Screenshot unten markiert, und fügen Sie eine Ansicht für Ihre Kamera hinzu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/5a190/Home_Assistant_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACZUlEQVQ4y43Q646aQBiAYa6iTVy1jXJSOQ8KCDLDGRVEBQYRcd1ttu39X0Gju9m2aWuaPJkfM/NOvgzRp8efmAnFSX163CVHXZLtkuzD8G19GDI9ku3dNt/1qBHDyz1qRHQGdGdA96hRnxp3BkxnQHeHTI8adYdMl2T7JPuBZD8OmYfbtXd9etwZ0AQtyLSgTMBM0uZjZcbKKisBTp2NZJUHM1rVNmPRGgufBYXiJPKG4mWKl2heJsr2iM9N2R6LU12cmqJt8LnJ6po3Xd0JdC/caeZTVeSXU1kfi6s6x4d9dUVscQGDlRMmKExRmMIgsdzlHMU0QKLmAM1hwEJz44UbW86VCaMwSjGuSoyJZJsZMPHiMkoPya51wj0nw4lsS1NHAIgHSFIdTrYnyhWn2CNpYaGwrvABl0S2zxTdc+Nct5emk+j2UlChOEWC+pM4RaL6hlcgdKKmxscDJrb7raR5OkqBGanzEBgBD+Cv5W+vqIhTIHSjtsGn+hpntp/BcKctYkGFd8rXeKJA5EWPLT43mNjnmW4v3ThfeBtg+MDwFf2fgOGLU9fxokuLH0+YyG+xovs8gLLm3afonnCLn87Vpa2use1ny+w4s6L/jF0/+vJYPZ0xUeSbOVqHSWXYK0FFd2Z+H9v1o68X/HKpiGK/QUEWrvHC25hOIk6dO6SZwwHkBdH5VJY4J3CeLoJdmNar7dFbForuvX7bn16PhKkbRvEu35t+SuBiA6w1MFdTKwZGOFEgp6C/kvVI1Hx+6ntB/HIpL21BNNVWM+zZjWZA3UT/YsJgbnu65a1Xy+/P1bfnww+3zvCc8aY/agAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/e4706/Home_Assistant_07.avif 230w", "/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/d1af7/Home_Assistant_07.avif 460w", "/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/c9875/Home_Assistant_07.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/a0b58/Home_Assistant_07.webp 230w", "/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/bc10c/Home_Assistant_07.webp 460w", "/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/d00b9/Home_Assistant_07.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/81c8e/Home_Assistant_07.png 230w", "/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/08a84/Home_Assistant_07.png 460w", "/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/5a190/Home_Assistant_07.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eba92e604d7ace202d3da4a7f9f7c6ae/5a190/Home_Assistant_07.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Wechseln Sie in die neue Ansicht und klicken Sie auf die große `}<strong parentName="p">{`+`}</strong>{`-Taste, um eine Karte hinzuzufügen, die unsere MQTT-Schalter enthalten soll:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "805px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/16276d6a3dfedec5e9a4708df7f6824d/c946b/Home_Assistant_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABKUlEQVQY0yXKXUvCUACA4f00P86WQd1kU4/TubkPUcuULWM5TIsupJvuqh8RFNSFdNFFhM1WQXhE3TnziOb6GWHCy3P1MrFsAe4dxgtVVtABVNi0upYTNACVdZygcWmV/S+SzMf1anq/DqDCRFP5bbm8mS2sD5BSNgSNhUooIQGoxDI6gGqIl8IJKZrKR5JyOC7GBHUrV4wkZSZRNlWrnalZmtVSrZZoNIr2mVxviqZdaXesi8vKaUeun+TMhnBwxJdM3rB3a8c7JYMvGYxLcd+fvFPPIZMe8RyK33zP8fHncvqxIH0fu3PyFUxdgnto5HwPhs1zImlX13c3jw4zDuj4l44COvyZrQpWosUMBXS4pGg+W7nwBxgjnyDPGz89k9v7h+5r98X9A4UWpdVfbm11AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/16276d6a3dfedec5e9a4708df7f6824d/e4706/Home_Assistant_08.avif 230w", "/en/static/16276d6a3dfedec5e9a4708df7f6824d/d1af7/Home_Assistant_08.avif 460w", "/en/static/16276d6a3dfedec5e9a4708df7f6824d/621da/Home_Assistant_08.avif 805w"],
              "sizes": "(max-width: 805px) 100vw, 805px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/16276d6a3dfedec5e9a4708df7f6824d/a0b58/Home_Assistant_08.webp 230w", "/en/static/16276d6a3dfedec5e9a4708df7f6824d/bc10c/Home_Assistant_08.webp 460w", "/en/static/16276d6a3dfedec5e9a4708df7f6824d/82ef0/Home_Assistant_08.webp 805w"],
              "sizes": "(max-width: 805px) 100vw, 805px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/16276d6a3dfedec5e9a4708df7f6824d/81c8e/Home_Assistant_08.png 230w", "/en/static/16276d6a3dfedec5e9a4708df7f6824d/08a84/Home_Assistant_08.png 460w", "/en/static/16276d6a3dfedec5e9a4708df7f6824d/c946b/Home_Assistant_08.png 805w"],
              "sizes": "(max-width: 805px) 100vw, 805px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/16276d6a3dfedec5e9a4708df7f6824d/c946b/Home_Assistant_08.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Wählen Sie eine `}<strong parentName="p">{`Entitätskarte`}</strong>{` und wählen Sie alle Schalter aus, die wir gerade zu unserer Konfiguration hinzugefügt haben. Sie sollten automatisch erscheinen - wenn nicht, versuchen Sie, den Home Assistant neu zu laden:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "868px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/748b0/Home_Assistant_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB5klEQVQoz1VS2XLiMBD0j2SLDUuA2JIsjQ7fknwJG6gQsnnY//+RLcvgQFU/TLWrp6dbDl5Wu5f1/tfb++se/d6jDaJ/wngdxm+Ivr6TDWIhFTsC65Cs38lqh1bbaEEQkkz3l2a4tuN3UZ8jVhFhMDdYGCKsh4knWI95NkQYqmyAWNEM39Zd2+Gvbi8id5PSA4Geh3AB6HlAfFoRRDSv3VfVflTdxbqr6T9nwWw7z+qORFjFTco1ARNLE2xDGbEKi5oXY5x0ES0nAWiW9aI60qRDoD9p+QHVRbVfmTtx84+WAjSRNthFighLk44XA8+HxY2lPeQHmvaIawCjWJWKukidYpX0cejkHMlZMDmrBt9zQnYQ5RGyA+YacY1Vw/UZypFmDvnMi7jG3IhypEm7lERkDfkB3w8hoKlqIe0xaOIZKm2w9WfHqpH6DNlP1Twfbs7zOtA+2nh7iLt4cobMifIoyjGW9S2zZ1jaL+XzYvRMh2+Z78406SaffLg761g2sjotu4iwopzEsWp9C49ny0ZUJ5b+nM1SB5ljcxAfWJQjL0Zf4fPZs9uixFz7p3I0aR/JBTfxDiVENAg0AvMIIptYdf7TE4/41G4E098erDaYQC5TIxL9BFWJZMYTLzObaycSneb2Pz4Zw3ut9S19AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/e4706/Home_Assistant_09.avif 230w", "/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/d1af7/Home_Assistant_09.avif 460w", "/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/67bb0/Home_Assistant_09.avif 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/a0b58/Home_Assistant_09.webp 230w", "/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/bc10c/Home_Assistant_09.webp 460w", "/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/1ae05/Home_Assistant_09.webp 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/81c8e/Home_Assistant_09.png 230w", "/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/08a84/Home_Assistant_09.png 460w", "/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/748b0/Home_Assistant_09.png 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e4d8c52b75f5751e11a77b62e95fc4c6/748b0/Home_Assistant_09.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "867px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/571db5c973df8bd64e9307c9bb86d70e/264eb/Home_Assistant_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABmUlEQVQ4y5WTW4/TMBSE+2/oJU3bxEnd3Ow4dtIm3bYp3aoFJHgAaZEQAsQTf37QcdmbKiD7MFIsxZ9nzti9gcuh6yOq9QlN+w559Rouk3BZ/gJJOJ6A46XoDV2OSK7hLwxYVGIaKox90VmjWQqfa+yPHy7A0TSCaY5I1caeRMDZvOisSZBjIWrcffmJaSjRc2YRmvYtpGktlH4gaFeRCZ6t8PHuG6aBRO+VEyLVe+RlC6F3iERjI7zIYVZfgOSQSjHteyzUDYJ0BZ7fYBzkneZH7miGYVLh0+fvmBFw6IQ4FRsYJiEmMdQkxtzLMKINHUuZJ5V1aIGDvoc3eotqdYA2OyyzGpoJuFzD+2tUZW9EEFd2js8dDnwczA5J2drYIl0ip9Np4z/K8Li2V42+qeUHhwS81Vtk5R6x2kBlKyj2CLzS/AIk2D0wks01UFR7JMUj0P0T7VrFA5BFxq6fA/s+bssWyfKARbGFyGqoDpEvDjXGTCJ+CuwPGc68wDkucYoMzlwhpbf8PyCVxjUcL0Ocr/H1xy8L/A1Ra7ujIUMbOQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/571db5c973df8bd64e9307c9bb86d70e/e4706/Home_Assistant_10.avif 230w", "/en/static/571db5c973df8bd64e9307c9bb86d70e/d1af7/Home_Assistant_10.avif 460w", "/en/static/571db5c973df8bd64e9307c9bb86d70e/0d127/Home_Assistant_10.avif 867w"],
              "sizes": "(max-width: 867px) 100vw, 867px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/571db5c973df8bd64e9307c9bb86d70e/a0b58/Home_Assistant_10.webp 230w", "/en/static/571db5c973df8bd64e9307c9bb86d70e/bc10c/Home_Assistant_10.webp 460w", "/en/static/571db5c973df8bd64e9307c9bb86d70e/415aa/Home_Assistant_10.webp 867w"],
              "sizes": "(max-width: 867px) 100vw, 867px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/571db5c973df8bd64e9307c9bb86d70e/81c8e/Home_Assistant_10.png 230w", "/en/static/571db5c973df8bd64e9307c9bb86d70e/08a84/Home_Assistant_10.png 460w", "/en/static/571db5c973df8bd64e9307c9bb86d70e/264eb/Home_Assistant_10.png 867w"],
              "sizes": "(max-width: 867px) 100vw, 867px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/571db5c973df8bd64e9307c9bb86d70e/264eb/Home_Assistant_10.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Verlassen Sie den Konfigurationsmodus, um zur normalen Benutzeroberfläche zurückzukehren, und Sie sollten Ihre neue Karte mit allen 4 Alarmbereichsschaltern sehen können. Versuchen Sie, Ihre Alarmbereiche von Ihren Kameras über die `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Home_Assistant/#hinzuf%C3%BCgen-des-live-videos-ihrer-kamera"
      }}>{`Web-Benutzeroberfläche`}</a>{` zu schalten. Sobald Sie Ihre Einstellungen dort vorgenommen haben, sollten alle Schalter in Home Assistant sofort auf die Zustandsänderung reagieren.`}</p>
    <p><img parentName="p" {...{
        "src": "/en/bb29134793e21c9b40382821318ed3c5/Home_Assistant_12.gif",
        "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera"
      }}></img></p>
    <h2 {...{
      "id": "hinzufügen-ihrer-kameras-live-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hinzuf%C3%BCgen-ihrer-kameras-live-video",
        "aria-label": "hinzufügen ihrer kameras live video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen Ihrer Kameras Live-Video`}</h2>
    <p>{`Wir haben bereits in einem früheren Tutorial gezeigt, wie Sie das Live-Video Ihrer Kamera zur `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Home_Assistant/#adding-your-cameras-live-video"
      }}>{`Lovelace-Benutzeroberfläche hinzufügen können`}</a>{`.`}</p>
    <h2 {...{
      "id": "hinzufügen-einer-ui-tastfläche",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hinzuf%C3%BCgen-einer-ui-tastfl%C3%A4che",
        "aria-label": "hinzufügen einer ui tastfläche permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer UI-Tastfläche`}</h2>
    <p>{`Einige der Funktionen unserer Kamera haben keine 2 Zustände (ein/aus) - wir brauchen nur eine Taste, die wir drücken können und sie sollte danach sofort wieder zurück springen. Beispiele sind:`}</p>
    <ul>
      <li parentName="ul">{`Manuelle Alarmauslösung: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`alarm/pushalarm`}</code></li>
      <li parentName="ul">{`Zur voreingestellten Position gehen: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`features/ptz/preset`}</code></li>
      <li parentName="ul">{`Nach oben/unten/links/rechts bewegen: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`features/ptz/move`}</code>{`  usw.`}</li>
    </ul>
    <p>{`Dies kann im Home Assistant mit einem Skript erfolgen. Stellen Sie sicher, dass Ihre `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`scripts.yaml`}</code>{` Datei im Ordner `}<strong parentName="p">{`.homeassistant`}</strong>{` vorhanden ist und mit Ihrer Hauptkonfiguration `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code>{` verknüpft ist - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`script: !include scripts.yaml`}</code>{`. Öffnen Sie dann die Skriptdatei und fügen Sie die folgenden Zeilen hinzu:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`9010_pushalarm`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`sequence`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt.publish
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/alarm/pushalarm
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`9010_gotopos1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`sequence`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt.publish
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/features/ptz/preset
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`9010_moveright`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`sequence`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt.publish
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/features/ptz/move
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"right"}'`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`9010_movestop`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`sequence`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt.publish
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/features/ptz/move
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"stop"}'`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span></code></pre></div>
    <p>{`Wählen Sie für jedes Skript einen Namen, der Ihnen hilft, die Kamera zu identifizieren, die Sie ansprechen möchten, z.B. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`9010_pushalarm`}</code>{`, und wählen Sie die MQTT-Topic aus, das Sie in Form einer Schaltflächen zu Ihrer Home Assistant UI hinzufügen möchten. Beachten Sie, dass der `}<strong parentName="p">{`move-Befehl`}</strong>{` den PTZ-Kopf Ihrer Kamera kontinuierlich bewegt, bis Sie den `}<strong parentName="p">{`stop-Befehl`}</strong>{` drücken - vergessen Sie nicht, die Stopptaste hinzuzufügen :).`}</p>
    <p>{`Genau wie bei unseren `}<a parentName="p" {...{
        "href": "#konfiguration-der-benutzeroberfl%C3%A4che"
      }}>{`Schalter`}</a>{` müssen wir nun den Konfigurationsmodus innerhalb der Home Assistant Lovelace UI aktivieren und auf die Schaltfläche `}<strong parentName="p">{`+`}</strong>{` klicken, um eine neue `}<strong parentName="p">{`Entity`}</strong>{` hinzuzufügen:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b10f5e616e9d356a497fe280256eac5/2e367/Home_Assistant_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABgUlEQVQY0zXL7U6CUACAYe6lP32tadGnWaFhQJpJoBjy7YEjhrhOAYGSKJKz2tq6k66wVWt7/rw/XmzvqEw21Zp+d9N1KEEvt43zX2VBLwv6+X/+If/cGrw1ZGSI5XaPT+rtS7VXN+44eH9tOBxwRTdoArduOFWtz6g2o9q00qOU3oUEK5JFiibZMUkRYBu5/TxBFdl2VYUt56GDIjvOBsnCeEr0MAXxAk7fwGQph2kLPdf6QcVEhOYUO1ZBMLDN/ME2cUGwLVa31HsP+CML+WiawShRHsdgPDfjhRbN249xE41oiEq6cyqah7yKX3d+ZpwgSZYXTeg+hcFs5j3HwSx9SFJnPOmPZ9ZoqnhxcxDwA58CblHt5yS4eSOv0jy2tXOULxKl2pWoachH6UuyfM8Wr+k0m4RJPIyiLvJatlvVexXZOhZBhZP9M0aiGnT1d17DD/BTgmnUu6YSBoPl3P9Yhm+ZN4mHQ2RLpkELYuGKw5nGxiXLEPTXyvpnoRTUuG+CqZxONISZFgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b10f5e616e9d356a497fe280256eac5/e4706/Home_Assistant_13.avif 230w", "/en/static/2b10f5e616e9d356a497fe280256eac5/d1af7/Home_Assistant_13.avif 460w", "/en/static/2b10f5e616e9d356a497fe280256eac5/7f308/Home_Assistant_13.avif 920w", "/en/static/2b10f5e616e9d356a497fe280256eac5/730d0/Home_Assistant_13.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b10f5e616e9d356a497fe280256eac5/a0b58/Home_Assistant_13.webp 230w", "/en/static/2b10f5e616e9d356a497fe280256eac5/bc10c/Home_Assistant_13.webp 460w", "/en/static/2b10f5e616e9d356a497fe280256eac5/966d8/Home_Assistant_13.webp 920w", "/en/static/2b10f5e616e9d356a497fe280256eac5/fbb05/Home_Assistant_13.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b10f5e616e9d356a497fe280256eac5/81c8e/Home_Assistant_13.png 230w", "/en/static/2b10f5e616e9d356a497fe280256eac5/08a84/Home_Assistant_13.png 460w", "/en/static/2b10f5e616e9d356a497fe280256eac5/c0255/Home_Assistant_13.png 920w", "/en/static/2b10f5e616e9d356a497fe280256eac5/2e367/Home_Assistant_13.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b10f5e616e9d356a497fe280256eac5/c0255/Home_Assistant_13.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/e2e58/Home_Assistant_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABLElEQVQY023O2U7CQBSAYd7AiGCFtlA7XSyVNdEbxXSm2JnOFGWWIkvQEC/0/Z/ATCEuicl/c5Lz5Zxa0zAtN+gEPSfs2SAwQWiCoF1lHsd/cqN+x49q9eZFy/G8eORfj8PBxI9HQX8SjW7CeAziIYiHbk8HosF3l1Hf8iPLu6qdnp032nZWlKvdx/r1c1G+QSzSXKVEpkQiIg4lRB6CubqDrOVHBgh/sFy/8+Wev+wZ3yKitP8VxYJVFUROITNA2HA8jZumDTFnfEcXG7bYkvnqAFB1HNKSYbFG8xI9lehpM3vOH3DdDU5st8Jt6z4tEFFJJmDGtTke1DjN1SORFAuKRZ7xgqhpQi3H63YqXDda07SY0aXe/vPtEesvcgWJhETO6PI2ob7tjO3uF/YTXnWqTr0eAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a3ec22fcd1773b6fa608a7c511239a8c/e4706/Home_Assistant_14.avif 230w", "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/d1af7/Home_Assistant_14.avif 460w", "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/7f308/Home_Assistant_14.avif 920w", "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/c986f/Home_Assistant_14.avif 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a3ec22fcd1773b6fa608a7c511239a8c/a0b58/Home_Assistant_14.webp 230w", "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/bc10c/Home_Assistant_14.webp 460w", "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/966d8/Home_Assistant_14.webp 920w", "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/b8544/Home_Assistant_14.webp 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a3ec22fcd1773b6fa608a7c511239a8c/81c8e/Home_Assistant_14.png 230w", "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/08a84/Home_Assistant_14.png 460w", "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/c0255/Home_Assistant_14.png 920w", "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/e2e58/Home_Assistant_14.png 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a3ec22fcd1773b6fa608a7c511239a8c/c0255/Home_Assistant_14.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Wählen Sie nun das Skript, das Sie dieser Schaltfläche zuweisen möchten aus der `}<strong parentName="p">{`Entity List`}</strong>{` und benennen Sie die Karte. Leider gibt uns die Benutzeroberfläche noch nicht die Möglichkeit, dem ausgewählten Skript einen `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Namen`}</code>{` und ein Symbol hinzuzufügen. Aber wenn Sie auf die beiden gewellten Klammern klicken - hier hervorgehoben (roter Pfeil):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/02f11356829e9b21886bd92a7a668b7a/e996b/Home_Assistant_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACAUlEQVQoz2MQlZCWUlKVVdGQVdFQUNVS1dFX0dJVVNdW0tRV0tKVV9eWU9OCIFk1TVlVBJJR1WQQEpGQUVZ1dPP19I/wDox08wm1dfGzc/Gzc4UhN38Iw9bFz9bF18bZ19bFT9fMRlFHn4FfSFRWWcUnMDokKjU8Nj0gLMHZM8TFKxQHCnPxDHX3idQzs5VR02IQFBaTUVTxCIgJikx19gpzcA929grDg5y8Ql19InRMrBUUVRkEhUVlFJW9g+JCYzMCwhJdvMMJaPYMdfOJ1DG2kldQBmmWV1b1CogKiUoNjU4LDE/Cr9/JM9TVJ1Lb2EpCUYVBQEhURkE5OCIxKDzJ2TPEzSfc1TvM1TvMDRty9Q5z9gxx9Q7TMrQQlVVgEBAWFZeRc3AL8AqI9vSL9PCLsHPxs3HCgZz9Hd2DPHwjNfVMhaVkGARFxPiExRLT8h48fHzx6s0zF66dPn/lxq27t+7cQ0M3bt15/OR5XWOnlVuQioGZgJgEKLT5hEWz88v/////7effL9//fPnx9++//5jgz58/////75ky29vW21ZDj09EFKSZV1AkKbPk1ae/919+vfXk44NX3158+PPqIzp68f7Xl1//67omh1q7ualo8ggKQzUnZpa8/PT/weuft198v//616vP/zDRi49/vvz939o12cLZX1HPBKgZADC1HKxWraKeAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02f11356829e9b21886bd92a7a668b7a/e4706/Home_Assistant_15.avif 230w", "/en/static/02f11356829e9b21886bd92a7a668b7a/d1af7/Home_Assistant_15.avif 460w", "/en/static/02f11356829e9b21886bd92a7a668b7a/7f308/Home_Assistant_15.avif 920w", "/en/static/02f11356829e9b21886bd92a7a668b7a/35372/Home_Assistant_15.avif 1050w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/02f11356829e9b21886bd92a7a668b7a/a0b58/Home_Assistant_15.webp 230w", "/en/static/02f11356829e9b21886bd92a7a668b7a/bc10c/Home_Assistant_15.webp 460w", "/en/static/02f11356829e9b21886bd92a7a668b7a/966d8/Home_Assistant_15.webp 920w", "/en/static/02f11356829e9b21886bd92a7a668b7a/c139f/Home_Assistant_15.webp 1050w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02f11356829e9b21886bd92a7a668b7a/81c8e/Home_Assistant_15.png 230w", "/en/static/02f11356829e9b21886bd92a7a668b7a/08a84/Home_Assistant_15.png 460w", "/en/static/02f11356829e9b21886bd92a7a668b7a/c0255/Home_Assistant_15.png 920w", "/en/static/02f11356829e9b21886bd92a7a668b7a/e996b/Home_Assistant_15.png 1050w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/02f11356829e9b21886bd92a7a668b7a/c0255/Home_Assistant_15.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`können sie dies manuell in die Entitätsdefinition aufnehmen:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entities`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.9010_pushalarm
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`icon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mdi:bell'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` IN`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`9010 FHD
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`show_header_toggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`title`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Trigger Alarm
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` entities`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/765ac9c8d054784e916f2186b3fdd524/2e694/Home_Assistant_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNklEQVQoz3XRTWjTABQH8FxFa5c29rvODoqOIWvTJmmbpGnznSbpp2uTklG1zKnDz7MXrx7H5qYb014UPIlXD+pRsBuednITcdMOBBWKX90iWcdAxcfv8Hi8/+U9APIGRpEEQYtkVkxlBARLYygJ4zRKssmMgKW5OMHEcRrG6RjBIgSLkOxoInPsxMlAeBhwenwUlzeal8cnrjTOXZMqDT6vC4rGyTVOrnJylZdrvGLZndQERcuKlaHw8GAoDLh9/liKfvTw8duV1TftlbX28vra+vuNzU5nq9PZ+vinjc0P3W53+vaiKxAKDh23wijFv3jy9Nvqu0779ZeXy+aPn6Zpmjs75j/V6/VM01xqPQB9R92hMAB5vPEU/ez5q+3vv75++mz2ts3/137Y7vK7giHA7oCiGHXj5q25hdbM/L3Zu/dn5pf2zP1tenbxzkJrcuq60xt0ePzAYQeEEizB5BFCREkJJUWEEBBcQAkxkZb2oaS4B+cxgj8SGLRBLsAGOhCcEVSNk8es2yq10xOXmuevGmcuqBWjL3/KkAo6m7MWRFWnuKLL7QNBJ3BowIGkaE6uMmKZFsu8Uh1vThlnL+qNSaVcl0t9Oq9UabHMSBU2N0bQqtPttQ04ABvoxEhOzNc565+aoGpyycgV67liXSroUkHPFaxeULX+qwVVz/Al0O07YN8Nj0RQDGeiWBpOUDBGRVEqlswiOJMguXiKjmJUBKUiaBrGqFgyA2OZkUgCdPsOgtBvLxcO7g2BhrcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/765ac9c8d054784e916f2186b3fdd524/e4706/Home_Assistant_16.avif 230w", "/en/static/765ac9c8d054784e916f2186b3fdd524/d1af7/Home_Assistant_16.avif 460w", "/en/static/765ac9c8d054784e916f2186b3fdd524/7f308/Home_Assistant_16.avif 920w", "/en/static/765ac9c8d054784e916f2186b3fdd524/7118b/Home_Assistant_16.avif 1048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/765ac9c8d054784e916f2186b3fdd524/a0b58/Home_Assistant_16.webp 230w", "/en/static/765ac9c8d054784e916f2186b3fdd524/bc10c/Home_Assistant_16.webp 460w", "/en/static/765ac9c8d054784e916f2186b3fdd524/966d8/Home_Assistant_16.webp 920w", "/en/static/765ac9c8d054784e916f2186b3fdd524/85e27/Home_Assistant_16.webp 1048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/765ac9c8d054784e916f2186b3fdd524/81c8e/Home_Assistant_16.png 230w", "/en/static/765ac9c8d054784e916f2186b3fdd524/08a84/Home_Assistant_16.png 460w", "/en/static/765ac9c8d054784e916f2186b3fdd524/c0255/Home_Assistant_16.png 920w", "/en/static/765ac9c8d054784e916f2186b3fdd524/2e694/Home_Assistant_16.png 1048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/765ac9c8d054784e916f2186b3fdd524/c0255/Home_Assistant_16.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Sie können Ihr Symbol aus den `}<a parentName="p" {...{
        "href": "https://cdn.materialdesignicons.com/4.5.95/"
      }}>{`Material Design Icons`}</a>{` auswählen. Beachten Sie, dass das Symbol, das wir hier gewählt haben - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Icon: mdi:bell'`}</code>{` - in der Material Design Dokumentation als `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mdi-bell`}</code>{` bezeichnet wird und dieser Name entsprechend geändert werden muss, damit der Heimassistent das Symbol erkennt.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ca08b851f7a83c19d10dac529eea103d/58fee/Home_Assistant_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC6UlEQVQozxXLWW/aBgAAYL9VndSXaZO2JrsS2qxJzGXAxoAdYxt8EYiNMYdtzBFuEmgcLpOEM0AStLaT1pdq6lv/ZpXv/QNgNOwJUsHIaSSupPJVWT+PZ4x42mDELCupXFLjZZ2XdVZSWSnDy6og61Q8HeaTLHsGgJA/wIuGadVvFo3Jpnq7bswe69Onm3+/TD5/vf//m/Xf196nLxfbzxebbWv1sb78oI6Wqa7VbF4Dhw6vnxaUalu/MIumZVxZWmeY6wzy17eNybqz+lCfPZ3fbbTBLD9a5IeLVHfMlDtMscUVmsCRE4ZJNpYzsvV26apXG4wrPatgDsr9m+bdfWe5rdyuC6O58n6c603U3kS6GNKFFp2v0VoVOHT4QlHBaLYbV9fdkWUtFtZy2ZvNrmfzwephsN7WxnPdHIstU+mMsldjsd2j8nVSrVDZMgC6/b4TSsxppUbDHJqL1fR+M9s8LdeP96vtw2SzrveH2VZHKNYS1Y7cMrlSE0sXcMUIyfpzdgVwVkymtVytUb6x3q/m/X8erI9Pt9vHu+ncOm83JKNIJjN0OsfpZSpjeGNJmBcpXgTsbvTQ4w/QEYrnRDlRq6j9bmlhNR6ml6tpd9RvaYUsJ4kIIwR4AYtLhJh20TyIURgRBewQ+sYBOYMYSlI4RYgSX8xL3aZqmeVxv3Z5WT5TEgTHufAwRDG+CI8wMYhkDuDQIUo8533QDaIhDx72YhjJRhLJmKbLpUquWNUULR0WOChMHsCoHSedRMSOkX+54D0n/M6LPmcbCDkDhJegvQQZYthoIi4oST6jMIocOj11krQNCe7YPcdBwoGTTox860H3HL6/PShw7EJsdg+EUTiXQCJcgBFOYmeEmCJS2RNFRRIySPP7CP7a7t0FPTvH7t1j929H7p/3j37aeweALsQGQq5gmOATPjLqDUeRCA8zcVgQ/WdpXzxlj57agtSvTvjHA/DVnwc//P7m5e7+y529F7/88R302QP2hOiIvgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca08b851f7a83c19d10dac529eea103d/e4706/Home_Assistant_17.avif 230w", "/en/static/ca08b851f7a83c19d10dac529eea103d/d1af7/Home_Assistant_17.avif 460w", "/en/static/ca08b851f7a83c19d10dac529eea103d/7f308/Home_Assistant_17.avif 920w", "/en/static/ca08b851f7a83c19d10dac529eea103d/cad33/Home_Assistant_17.avif 1051w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ca08b851f7a83c19d10dac529eea103d/a0b58/Home_Assistant_17.webp 230w", "/en/static/ca08b851f7a83c19d10dac529eea103d/bc10c/Home_Assistant_17.webp 460w", "/en/static/ca08b851f7a83c19d10dac529eea103d/966d8/Home_Assistant_17.webp 920w", "/en/static/ca08b851f7a83c19d10dac529eea103d/42749/Home_Assistant_17.webp 1051w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca08b851f7a83c19d10dac529eea103d/81c8e/Home_Assistant_17.png 230w", "/en/static/ca08b851f7a83c19d10dac529eea103d/08a84/Home_Assistant_17.png 460w", "/en/static/ca08b851f7a83c19d10dac529eea103d/c0255/Home_Assistant_17.png 920w", "/en/static/ca08b851f7a83c19d10dac529eea103d/58fee/Home_Assistant_17.png 1051w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ca08b851f7a83c19d10dac529eea103d/c0255/Home_Assistant_17.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Hier haben wir alle Pan-Tilt, Preset Position und den Befehl Manual Alarm Trigger zur Lovelace UI hinzugefügt.`}</p>
    <h2 {...{
      "id": "automatisierungen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#automatisierungen",
        "aria-label": "automatisierungen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Automatisierungen`}</h2>
    <p>{`Nachdem wir jetzt in der Lage sind, Funktionen auf unserer Kamera manuell auszulösen, wollen wir nun einen Weg finden, diese Prozesse durch den Home Assistant zu automatisieren. Dazu werden wir zunächst einen Prototyp-Service zu unserer Datei `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`scripts.yaml`}</code>{` hinzufügen. Dieser Dienst soll in der Lage sein, ein `}<strong parentName="p">{`MQTT Topic`}</strong>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`target`}</code>{`) und `}<strong parentName="p">{`MQTT Payload`}</strong>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`message`}</code>{`) von einer Automatisierung in der Datei `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`automations.yaml`}</code>{` zu empfangen. Dies kann durch Hinzufügen der folgenden Zeilen in die Scripts Datei geschehen:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`scripts.yaml`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# MQTT Publish Service`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`send_mqtt_command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`sequence`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt.publish
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ target }}"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ message }}"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span></code></pre></div>
    <p>{` Stellen Sie sicher, dass die Datei `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`automations.yaml`}</code>{` im `}<em parentName="p">{`.homeassistant`}</em>{` Verzeichnis vorhanden ist und mit der Haupt-Konfigurationsdatei `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code>{` verknüpft ist - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`automation: !include automations.yaml`}</code>{`. Wechseln Sie dann zur Lovelace-Benutzeroberfläche, öffnen Sie das `}<strong parentName="p">{`Einstellungen Panel`}</strong>{` und wählen Sie `}<strong parentName="p">{`Automation`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eeb2493f5a354fff35e182dab49f3929/798d4/Home_Assistant_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABl0lEQVQoz43SS2/TQBSG4fwfWlKI8SUe23PxmZvH9owvsWPTBgFbVkis2LHiX6O2SYjqSK30bF+dT6NZBTH7FOI4AVP31u0xVV6Q+RH2I/Kq1Z2Hgi2LEMSZEOUEZp/yPhNdSvVVmBUJlsGWhXG+enfn+zELEYQICncPZiTCUWEpr67KZZ2xIojzEMHq9mN0jjFvUt4mVCVEJkQ9e3E5YwXC8hjffAjOMS86UC5hZZpXT8pH12N4vhyeY1XPsp6p2j3SA9UDK8bl7JSeZq+9+H9sZzt8U3bGoqFyR2RLwBAoL1FepVQf4/eb7cXlSdYTES2V3ZOWQPXWWNt75R6I3J2W74ns3hzXsxu/K/uZiI5y+yJ7Jeb1VA1fmRkz2WfCZlAuYV4ll3GIYJvKLQJrhqE9lKrXvClEp1mxZHJDiTq99iZae+hmk+QhPhS7h+5LwxsHjSXaErXUUN0jOMbu1vv14+fvP381VKPsOtHa3LrcOqIdUUst1UUmjrFe+03ZN8MBYQXllJsJqwGLGoPBUC5RUSenv/0PL3vxxYpaCXIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eeb2493f5a354fff35e182dab49f3929/e4706/Home_Assistant_18.avif 230w", "/en/static/eeb2493f5a354fff35e182dab49f3929/d1af7/Home_Assistant_18.avif 460w", "/en/static/eeb2493f5a354fff35e182dab49f3929/7f308/Home_Assistant_18.avif 920w", "/en/static/eeb2493f5a354fff35e182dab49f3929/aebcf/Home_Assistant_18.avif 976w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eeb2493f5a354fff35e182dab49f3929/a0b58/Home_Assistant_18.webp 230w", "/en/static/eeb2493f5a354fff35e182dab49f3929/bc10c/Home_Assistant_18.webp 460w", "/en/static/eeb2493f5a354fff35e182dab49f3929/966d8/Home_Assistant_18.webp 920w", "/en/static/eeb2493f5a354fff35e182dab49f3929/a8e2b/Home_Assistant_18.webp 976w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eeb2493f5a354fff35e182dab49f3929/81c8e/Home_Assistant_18.png 230w", "/en/static/eeb2493f5a354fff35e182dab49f3929/08a84/Home_Assistant_18.png 460w", "/en/static/eeb2493f5a354fff35e182dab49f3929/c0255/Home_Assistant_18.png 920w", "/en/static/eeb2493f5a354fff35e182dab49f3929/798d4/Home_Assistant_18.png 976w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eeb2493f5a354fff35e182dab49f3929/c0255/Home_Assistant_18.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "testen-sie-den-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#testen-sie-den-service",
        "aria-label": "testen sie den service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testen Sie den Service`}</h3>
    <p>{`Klicken Sie auf die rote Schaltfläche `}<strong parentName="p">{`+`}</strong>{`, um eine neue Automatisierung hinzuzufügen. Füllen Sie dafür das Formular wie folgt aus:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a02eae81674703ff4b0f10a92428de5e/bf433/Home_Assistant_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABjklEQVQoz32S23KbMBRF+aQ2cRFX3S+AriCDHVI7aR/7/x/QIcSOPbYzsx6khzVn62wloKAllKIJ2m1V1xs3qdZvANoAlObkERuAQpyT5wxllchrgZim0lHpiLCYm5q0oOTfUKIm+fGrymuZFly0Q5yOIb76YR7Ggw37rBLfAEqe/EzrvJZZJSrcYG4g7RDTiGnMbQFlXt/h7H/KoGCIO93PrYmdm3gTINNE2FswN2f/JJccMqPDi+lnH38rvcXCYeGvcVg4tMinyU8ArTJiVnZDVonnDKcFOy2GgZKt19tnJ08ArjIVzg0zlR5SjaiGCx3mFtLl/TXpHsppwRo7jvv3cf8+vfyJ0zHu3uLubRgP2u/9MBu/K6B8OBlSzZWnyjHlibBULofqo+3sIvNl/i+5xC1WA6S6pt2a+VzbJUTYr22fY/M2ht1fLByRnqn+47dZelMVvZJTuPaMhXfbYxgXWNMT6e+Chbszmap+e/jXhIPUkalApb8LuZY/e65JJ7oBMQOZQdw+AjJzXth/M9Tr+5e5ESQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a02eae81674703ff4b0f10a92428de5e/e4706/Home_Assistant_19.avif 230w", "/en/static/a02eae81674703ff4b0f10a92428de5e/d1af7/Home_Assistant_19.avif 460w", "/en/static/a02eae81674703ff4b0f10a92428de5e/7f308/Home_Assistant_19.avif 920w", "/en/static/a02eae81674703ff4b0f10a92428de5e/20fe0/Home_Assistant_19.avif 971w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a02eae81674703ff4b0f10a92428de5e/a0b58/Home_Assistant_19.webp 230w", "/en/static/a02eae81674703ff4b0f10a92428de5e/bc10c/Home_Assistant_19.webp 460w", "/en/static/a02eae81674703ff4b0f10a92428de5e/966d8/Home_Assistant_19.webp 920w", "/en/static/a02eae81674703ff4b0f10a92428de5e/3697b/Home_Assistant_19.webp 971w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a02eae81674703ff4b0f10a92428de5e/81c8e/Home_Assistant_19.png 230w", "/en/static/a02eae81674703ff4b0f10a92428de5e/08a84/Home_Assistant_19.png 460w", "/en/static/a02eae81674703ff4b0f10a92428de5e/c0255/Home_Assistant_19.png 920w", "/en/static/a02eae81674703ff4b0f10a92428de5e/bf433/Home_Assistant_19.png 971w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a02eae81674703ff4b0f10a92428de5e/c0255/Home_Assistant_19.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd63c6c222784b85df734bf79071853f/09e48/Home_Assistant_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABtElEQVQoz2WSWY+bMBRG5we102UKYQcbbxgMZncSJiFJq6qq1P//OGInqnRkfS9Hd/F9+fzd1C1oONgJWK36srk0x1ul+kr1teqr9lrU77XqI9HYPjvY2HA2Xj59MxY5ao63sr2253t7vh+7R3O8tae76h6qewipRhk9yWtl22ey6vK6SzJFeEl4gagcQ4mYZHHlAq5b4d4fKmuDTAwH+2ECcOpB7gRsJLL9OTgBcwH3YOzB2HTJLL++WfoiOwHzIPfD2A/jACVTGPISJnbyD3tq2/IoFw3AQjOhboWaCTUr1Bc0Ex5sNLG1vZNJlNQsrkIqEZOEF1PALMdRjpi0PLLf1pNsuNj2ieNTD/KReGQNseXRiU3+MsvEcBBNVZSdhmlREvwHwAJgMbZAV3ldGBJVL5uexBWJK8QkwLPwBEk3eb9tnp2y+pKVnaw6JhpAMkglpBKQBZpDVqzDb7LpYsRrxGtI0pBmy1cN71gzXRBb5a+ao1vQdMnBRmXzfvv193r/8/P3P3V+yLor20vRXHCUAywgSScG2Z4v7LBeGCBZgNMAZ4BkIZMhyycCnPpIrKxH8gEwH+EMmalZBgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd63c6c222784b85df734bf79071853f/e4706/Home_Assistant_20.avif 230w", "/en/static/bd63c6c222784b85df734bf79071853f/d1af7/Home_Assistant_20.avif 460w", "/en/static/bd63c6c222784b85df734bf79071853f/7f308/Home_Assistant_20.avif 920w", "/en/static/bd63c6c222784b85df734bf79071853f/5578a/Home_Assistant_20.avif 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd63c6c222784b85df734bf79071853f/a0b58/Home_Assistant_20.webp 230w", "/en/static/bd63c6c222784b85df734bf79071853f/bc10c/Home_Assistant_20.webp 460w", "/en/static/bd63c6c222784b85df734bf79071853f/966d8/Home_Assistant_20.webp 920w", "/en/static/bd63c6c222784b85df734bf79071853f/7efa9/Home_Assistant_20.webp 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd63c6c222784b85df734bf79071853f/81c8e/Home_Assistant_20.png 230w", "/en/static/bd63c6c222784b85df734bf79071853f/08a84/Home_Assistant_20.png 460w", "/en/static/bd63c6c222784b85df734bf79071853f/c0255/Home_Assistant_20.png 920w", "/en/static/bd63c6c222784b85df734bf79071853f/09e48/Home_Assistant_20.png 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd63c6c222784b85df734bf79071853f/c0255/Home_Assistant_20.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Um unsere Automatisierung zu testen, wollen wir ein MQTT-Topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hass/automation/test`}</code>{` einrichten, das, wenn es ein Payload von `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val": "1"}`}</code>{` empfängt, unseren oben erstellten Prototyp-MQTT-Dienst aufruft und eine `}<strong parentName="p">{`Message`}</strong>{` und ein `}<strong parentName="p">{`Target`}</strong>{` an ihn weitergibt:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"message"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"1\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"target"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/000389888811/features/ptz/preset"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Beachten Sie, dass wir, um eine JSON-formatierte Payload innerhalb dieses JSON-Ausdrucks senden zu können, die Anführungszeichen mit einem Backslash umgehen müssen.`}</p>
    <p>{`Durch das Senden des Payloads `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/features/ptz/preset`}</code>{` bewegen wir unsere Kamera auf `}<strong parentName="p">{`Preset Position 2`}</strong>{`. Wir können dies mit `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_und_LINUX_Apps/"
      }}>{`MQTT.fx`}</a>{` testen:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a842256f91a02cc631150ac9a88faf4/d1d24/Home_Assistant_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABH0lEQVQY02WOy0rDUBRF80dtk3tvclPT5mmgAxFBApK2OBGKOhI/IOIoLxyJX+Ggg1qbQj9tSVMciIPF5pyzOXsb6881u903h8OB/X7/l67r6bqO3a77dz/uf3W73bLZfGGoaYrruti2jZQSpVSv4sjYxw5mTIOQKIoQQmBZFkMhEJbVzycspFAEiYsR5s/E13dEwZQgDPGDgCiOifwAb3yGcjSOo9FaI5VCS8lqYGJJiZTiFN4/lWitMJT2UPqsb9a3U4rF2OMpSXmIE26zjHyxYJ7Pmec3zC4zltkj0dWSdHZBksQkyTlpmuJNfAxzNKTHNBmMhkwch4/VPe/FC1VV0dYNTdvSHmkayqrmtXmjrFuqqu49ZVnSNA1FUfADwHHXgdQlXCYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a842256f91a02cc631150ac9a88faf4/e4706/Home_Assistant_21.avif 230w", "/en/static/4a842256f91a02cc631150ac9a88faf4/d1af7/Home_Assistant_21.avif 460w", "/en/static/4a842256f91a02cc631150ac9a88faf4/7f308/Home_Assistant_21.avif 920w", "/en/static/4a842256f91a02cc631150ac9a88faf4/ce1e0/Home_Assistant_21.avif 987w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a842256f91a02cc631150ac9a88faf4/a0b58/Home_Assistant_21.webp 230w", "/en/static/4a842256f91a02cc631150ac9a88faf4/bc10c/Home_Assistant_21.webp 460w", "/en/static/4a842256f91a02cc631150ac9a88faf4/966d8/Home_Assistant_21.webp 920w", "/en/static/4a842256f91a02cc631150ac9a88faf4/b0d42/Home_Assistant_21.webp 987w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a842256f91a02cc631150ac9a88faf4/81c8e/Home_Assistant_21.png 230w", "/en/static/4a842256f91a02cc631150ac9a88faf4/08a84/Home_Assistant_21.png 460w", "/en/static/4a842256f91a02cc631150ac9a88faf4/c0255/Home_Assistant_21.png 920w", "/en/static/4a842256f91a02cc631150ac9a88faf4/d1d24/Home_Assistant_21.png 987w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a842256f91a02cc631150ac9a88faf4/c0255/Home_Assistant_21.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Ein Klick auf Publizieren löst unsere Automatisierung aus, die dann unseren Service dazu veranlasst, dass unsere Kamera zu Preset 2 wechselt.`}</p>
    <p>{`Wenn Sie die Datei `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`automations.yaml`}</code>{` im Verzeichnis `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.homeassistant`}</code>{` überprüfen, werden Sie sehen, dass der Home Assistant die Automatisierung für uns wie folgt erstellt hat:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1571128164029'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` TEST MQTT Automation
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payload`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`topic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` hass/automation/test
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/features/ptz/preset
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command`}</code></pre></div>
    <h3 {...{
      "id": "tagnachtschaltung-der-erkennungsbereiche",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tagnachtschaltung-der-erkennungsbereiche",
        "aria-label": "tagnachtschaltung der erkennungsbereiche permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tag/Nachtschaltung der Erkennungsbereiche`}</h3>
    <p>{`Nachdem wir nun bewiesen haben, dass unser Konzept solide ist, können wir fortfahren und unsere erste sinnvolle Automatisierung aufbauen. Einer unserer 12 `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/Home_Assistant/#4-verwenden-sie-f%C3%BCr-jede-position-unterschiedliche-erkennungsbereiche"
      }}>{`alltäglichen Anwendungsfälle`}</a>{` für Smarthome-Systeme, nutzt verschiedene Detektionsbereiche für Tag und Nacht.`}</p>
    <p>{`Dazu können wir wieder unseren Prototyp-MQTT-Service nutzen und einer Automatisierung - ausgelöst durch einen Zeitplan oder zeitgesteuerte Ereignisse wie Sonnenauf- und -untergang - die notwendigen MQTT-Topics und Payloads übergeben, um unsere Erkennungsbereiche ein- oder auszuschalten:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6bd7d27759c9c21d91bbad937195ee6c/561da/Home_Assistant_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABiUlEQVQoz22S6Y6bMBSF8z5VR1VJic1i8HZt4wUwg5uJmqh9/5eoCJNFGaTv76fje3x2ecFxJUsC1s+qG42dmPRZTnJEDwXLMf3KoWBZToybdm9ZsUcU14Br4NBz6BkEJkPZ6LzgqJIblAu4krtvP/I9anENqJJ+PIZ4CuNHiCfoprLR2/KN3fefeJGrRa6paYVruG2549C3whUEyka9sCahNTm7Jh9KrvzRT3/8eAI7tdJT6JnaoGz0oRRPyYssqAxgJ7DvDPqaW8IdYfZOfeOR/JbhPf6UbUg2pD2ivzDLC/7CkrZyv/kuo0oKNUgTwUShR6lHoQdhRmmi1AN08f7aF5mia2ExXebj3/TxL6ZzTJfp9yXOZ+NnZacuJMLspvxou2EdoR1h3eedtFsngGsoyNq23ryZg0vCHVvhqQxL21eWzTwhzVC1t7afC3ND0nayIRmf/HiiN/+Fja9ClaAwMDNLlxgEoYdGuK+0wj3kZduYrslMRzOeVTgpl8C+K5c2sHPZqFX+D8Oy2npCcKHNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6bd7d27759c9c21d91bbad937195ee6c/e4706/Home_Assistant_22.avif 230w", "/en/static/6bd7d27759c9c21d91bbad937195ee6c/d1af7/Home_Assistant_22.avif 460w", "/en/static/6bd7d27759c9c21d91bbad937195ee6c/7f308/Home_Assistant_22.avif 920w", "/en/static/6bd7d27759c9c21d91bbad937195ee6c/08130/Home_Assistant_22.avif 969w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6bd7d27759c9c21d91bbad937195ee6c/a0b58/Home_Assistant_22.webp 230w", "/en/static/6bd7d27759c9c21d91bbad937195ee6c/bc10c/Home_Assistant_22.webp 460w", "/en/static/6bd7d27759c9c21d91bbad937195ee6c/966d8/Home_Assistant_22.webp 920w", "/en/static/6bd7d27759c9c21d91bbad937195ee6c/bd9c6/Home_Assistant_22.webp 969w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6bd7d27759c9c21d91bbad937195ee6c/81c8e/Home_Assistant_22.png 230w", "/en/static/6bd7d27759c9c21d91bbad937195ee6c/08a84/Home_Assistant_22.png 460w", "/en/static/6bd7d27759c9c21d91bbad937195ee6c/c0255/Home_Assistant_22.png 920w", "/en/static/6bd7d27759c9c21d91bbad937195ee6c/561da/Home_Assistant_22.png 969w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6bd7d27759c9c21d91bbad937195ee6c/c0255/Home_Assistant_22.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e205cce36ba20146fa144c613f50373c/561da/Home_Assistant_23.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB2UlEQVQoz22S23KbMBCG/TC9a91D7BoM2Gh1BiQkztTH1Jm0uchMH6Iv3gESTBPPfBerGf36d//VbP7NXbpoveFuwLRtY12vPPIKndTE8akbsCmzj19Xi1fxFmJgmghDhKHSEmm7QlgqLZOZtxVvnph9mjhvIAmJQiQBqtAEYBqY3kAUILmBaLg8ODsLpxM7HhG6tc0DFnZLUkR1J5s8gbnGXDNpAxQN/rP53XpwdjwidZM3Z2XaxLZSVYimwN7BjR/KF/HnxXrpQif2CU3qOD9LXcu04XGJmBkAZoDbASwLH90S87iodhdb7Mr6pEzDIsvjjMcZESkwNYCZvuG88kiky2Z/KX/c706Pzf5iy0NWHfP6FOkKmMY87bnVthswP5ReKP0w8jriAJIOFG+w2hI94m3FS9qTtimWOVctYvq6pP93hqgiwgTjzGPaK48k+aE5PUVpLVTF46IfUr8B8zQI34kdn7KkMuU5Lfa2Oia2BW6wsFjYMWrgGY6K68xflv73Nbi9GETGVNPvU4ckGRk/We88SfvDfHnnhEPbytQPv56PP58en/4c7n/b8pDXp7I9S10ipoGn0Ad+Ff81tQzFsjtQYGnfpCEio1E+wKIC82z8MIhdV/UPEbTxTIglNqMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e205cce36ba20146fa144c613f50373c/e4706/Home_Assistant_23.avif 230w", "/en/static/e205cce36ba20146fa144c613f50373c/d1af7/Home_Assistant_23.avif 460w", "/en/static/e205cce36ba20146fa144c613f50373c/7f308/Home_Assistant_23.avif 920w", "/en/static/e205cce36ba20146fa144c613f50373c/08130/Home_Assistant_23.avif 969w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e205cce36ba20146fa144c613f50373c/a0b58/Home_Assistant_23.webp 230w", "/en/static/e205cce36ba20146fa144c613f50373c/bc10c/Home_Assistant_23.webp 460w", "/en/static/e205cce36ba20146fa144c613f50373c/966d8/Home_Assistant_23.webp 920w", "/en/static/e205cce36ba20146fa144c613f50373c/bd9c6/Home_Assistant_23.webp 969w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e205cce36ba20146fa144c613f50373c/81c8e/Home_Assistant_23.png 230w", "/en/static/e205cce36ba20146fa144c613f50373c/08a84/Home_Assistant_23.png 460w", "/en/static/e205cce36ba20146fa144c613f50373c/c0255/Home_Assistant_23.png 920w", "/en/static/e205cce36ba20146fa144c613f50373c/561da/Home_Assistant_23.png 969w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e205cce36ba20146fa144c613f50373c/c0255/Home_Assistant_23.png",
              "alt": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "title": "Home Assistant mit Ihrer INSTAR IP-Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Dies ist die `}<strong parentName="p">{`Tag Automation`}</strong>{`, die durch den `}<strong parentName="p">{`Sonnenaufgang`}</strong>{` ausgelöst wird und die MQTT-Topics aktualisiert, um die Alarmdetektionsbereiche 1 & 2 zu aktivieren, während die Bereiche 3 & 4 deaktiviert werden. Beachten Sie, dass diese Automatisierung 4 Aktionen kombiniert.`}</p>
    <p>{`Die `}<strong parentName="p">{`Nacht Automation`}</strong>{` macht das Gegenteil - sie wird ausgelöst durch den `}<strong parentName="p">{`Sonnenuntergang`}</strong>{` und deaktiviert die Bereiche 1 & 2, während die Bereiche 3 & 4 aktiviert werden.`}</p>
    <p>{`Ein kurzer Blick in die Datei `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`automations.yaml`}</code>{` zeigt uns die beiden Automatisierungen, die mit der Lovelace-Benutzeroberfläche erstellt wurden:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1571131738957'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Switch to Night Areas
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` sunset
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` sun
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/alarm/area1/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/alarm/area2/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/alarm/area3/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/alarm/area4/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command

    
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1571131880630'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Switch to Day Areas
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` sunrise
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` sun
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/alarm/area1/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/alarm/area2/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/alarm/area3/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/000389888811/alarm/area4/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command`}</code></pre></div>
    <p>{`Sie können nun auf den nächsten Sonnenaufgang oder Sonnenuntergang warten, um zu sehen, ob Ihre Automatisierung funktioniert (oder verwenden Sie einfach die Home Assistant Developer Tools innerhalb der Lovelace Benutzeroberfläche, um dieses Ereignis von Hand auszulösen).`}</p>
    <h2 {...{
      "id": "verwendung-von-home-assitant-als-alarmserver-für-ihre-kamera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#verwendung-von-home-assitant-als-alarmserver-f%C3%BCr-ihre-kamera",
        "aria-label": "verwendung von home assitant als alarmserver für ihre kamera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Verwendung von Home Assitant als Alarmserver für Ihre Kamera`}</h2>
    <p>{`Wir haben bereits in einem früheren Tutorial gezeigt, wie Sie die `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Home_Assistant/#verwendung-von-home-assistant-als-alarmserver-f%C3%BCr-ihre-instar-kamera"
      }}>{`Alarmserver-Funktion Ihrer Kamera`}</a>{` nutzen können um Home Assistant bei einem Alarm zu benachrichtigen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      